import React, { useState } from "react";
// import { submitMail2 } from "../utils/submitMail2DB";

export default function Modal() {
  const [emailAddress, setEmailAddress] = useState("");
  const [modalContent, setModalContent] = useState("");

  const submitMail = async () => {
    if (emailAddress.trim().length === 0) {
      setModalContent("Please provide a valid email address.");
    } else {
      const myData = { mail: emailAddress };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_MAIN_SERVER}/submitmail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(myData),
          }
        );
        // console.log(response);
        const result = await response.json();
        setModalContent("Thanks! Your mail was successfully added.");
      } catch (error) {
        setModalContent("An error occurred. Please try again later.");
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setEmailAddress(e.target.value);
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Thank you for your interest!
            </h5>
            <button
              type="button"
              className="btn-close btn-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>
              We are still in the process of setting up our service and need a
              little more time... Tell us your e-mail address and we'll notifiy
              you as soon as we're ready for you.
            </p>
            <form
              action="https://pedalytics.us8.list-manage.com/subscribe/post?u=ba898ca9f776f4b1457ab1c38&amp;id=6705751add&amp;f_id=00af60e1f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
            >
              <label>your e-mail address</label>
              <input
                type="email"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                required=""
                value={emailAddress}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                /* real people should not fill this in and expect good things -
                do not remove this or risk form bot signups */
                <input
                  type="text"
                  name="b_ba898ca9f776f4b1457ab1c38_6705751add"
                  tabIndex="-1"
                  value=""
                />
              </div>

              <div id="mce-responses" class="clear foot">
                <div
                  class="response"
                  id="mce-error-response"
                  // style={{ display: "none" }}
                ></div>
                <div
                  class="response"
                  id="mce-success-response"
                  // style={{ display: "none" }}
                ></div>
              </div>
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="btn btn-primary"
                value="Yes, notify me"
              />
              {/* <input
                type="email"
                placeholder="your email address"
                value={emailAddress}
                required
                onChange={(e) => {
                  handleChange(e);
                }}
              ></input> */}
            </form>
          </div>
          {modalContent && (
            <div className="modal-footer">
              <p>{modalContent}</p>
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark"
              data-bs-dismiss="modal"
            >
              No thanks
            </button>
            {/* <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                submitMail();
              }}
            >
              Yes, notify me
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
